<template>
  <el-container :class="classObj" class="ym-layout">
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <el-aside :class="menuButton">
      <div>
          <div class="logo-box" v-if="logoOptions" :style="{ left: logoOptions.left }" @click="logoClick">
            <logo :logoOptions="logoOptions" :show="sidebar.opened"></logo>
          </div>
        <ym-menu :routes="sidebarRouters" v-if="permissiveRoutes"></ym-menu>
        <ym-menu :routes="routers" v-else></ym-menu>
      </div>
      <!-- 菜单isCollapse切换按钮 -->
      <div :class="['ym-menu-collapse', 'hidden-sm-and-down', sidebar.opened ? 'open' : '']">
        <menu-icon
          :is-active="sidebar.opened"
          @toggleClick="toggleSideBar"
        />
      </div>
    </el-aside>
    <el-container class="ym-body">
      <hearder
        position="fixed"
        showWelcome
        :userInfo="userInfo"
        :rightMenu="rightMenu"
        :logoOption="logoOption"
        @logoClick="logoClick"
        @headerImgClick="headerImgClick"
        @dropdownItemClick="dropdownItemClick"
        :menuButton="menuButton"
      ></hearder>
      <el-main>
        <breadcrumb :bottom="12" :top="8"></breadcrumb>
        <transition name="fade-transverse" mode="out-in">
          <router-view class="ym-content" />
        </transition>
        <!-- 备案号 -->
        <!-- <div class="bottom-box">
          <span>Copyright © 2014-2015 All Rights Reserved</span>
          <div class="bottom-label">
            <span>中机联基石链区块浏览器 中机联科技（广东）有限公司&ensp;</span>
            <a class="beian-label" href="http://beian.miit.gov.cn/" target="_blank">粤ICP备19159742号-1</a>
          </div>
          <span>佛山市南海区桂城街南平西路广东夏西国际橡塑城一期5号楼20046</span>
        </div> -->
      </el-main>
    </el-container>
    <!-- <setting></setting> -->
  </el-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Hearder from './Header.vue'
import ymMenu from './Menu/index.vue'
import MenuIcon from './Menu/MenuIcon'
import Logo from './Logo'
// import Setting from './Setting.vue'

export default {
  name: 'home',
  components: {
    Hearder,
    ymMenu,
    MenuIcon,
    Logo
    // Setting
  },
  created() {
    this.permissiveRoutes = window.PERMISSIVE_ROUTES
  },
  data() {
    return {
      menuButton: 'left', // top left right 按钮位置
      routes: [],
      rightMenu: [],
      permissiveRoutes: true
    }
  },
  computed: {
    ...mapGetters(['sidebarRouters']),
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    routers() {
      return JSON.parse(JSON.stringify(this.$router.options.routes))
    },
    logoOption() {
      return {
        logoType: 'img',
        title: '公司LOGO',
        url: this.sidebar.opened ? require('@/assets/img/logo-header.png') : require('@/assets/img/logo-header1.png')
      }
    },
    logoOptions() {
      return Object.assign({
        overflow: true
      }, this.logoOption)
    },
    ...mapGetters(['userInfo', 'sidebar'])
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    logoClick() {
      console.log('点击logo')
    },
    ...mapActions(['logout']),
    headerImgClick() {
      console.log('点击头像')
    },
    // 下拉菜单点击
    dropdownItemClick(item) {
      if (item.url && this.$route.path !== item.url) {
        this.$router.push({ path: item.url })
      }
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    }
  },
  filters: {}
}
</script>

<style lang="scss">
$header-height: $--header-height;
.ym-layout {
  height: 100%;
  overflow: hidden;

  .el-aside {
    transition: width 0.3s;
    text-align: left;
    background-color: $--aside-bg-color;
    width: $--aside-width !important;
    box-shadow: $--aside-shadow;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &.top .ym-menu-collapse {
      display: none;
    }
    &.left {
      // margin-bottom: 47px;
      .ym-menu-collapse {
        height: 56px;
        line-height: 56px;
        &.open {
          padding: 0 15px;
        }
      }
    }
    &.right {
      .ym-menu-collapse {
        right: 20px;
        bottom: 30px;
        &-icon {
          background-color: #eee;
          border-radius: 50%;
          &:hover {
            background: rgba(0, 0, 0, 0.025);
          }
        }
      }
    }
  }

  .ym-body {
    display: flex;
    flex-direction: column;

    .ym-menu-collapse {
      position: fixed;
      z-index: 10;
      &-icon {
        width: 48px;
        height: 48px;
        line-height: 48px;
        cursor: pointer;
        transition: background 0.3s;
        -webkit-tap-highlight-color: transparent;
      }
    }

    .el-main {
      height: calc(100% - 72px);
      width: 100%;
      padding: 8px 8px 0px 8px;
      overflow: auto;
      background-color: $--background-color-base;
      .ym-content {
        /*background-color: #fff;*/
        /*box-shadow: 0px 0px 27px 0px rgba(146, 146, 146, 0.28);*/
        /*border-radius: 8px;*/
      }
      &::after {
        content: "";
        display: block;
        height: 16px;
        visibility: hidden;
      }
      .bottom-box{
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 12px;
        color: #888;
        margin-top: 20px;
        margin-bottom: 10px;
        .bottom-label{
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .beian-label{
          color: #333;
        }
        .beian-label:hover{
          cursor: pointer;
          color: red;
          text-decoration: underline;
        }
      }
    }
    // 手机版
    @media screen and (max-width: 768px) {
      .el-main {
        padding: 20px 0px;
      }
    }
  }
  &.mobile {
    .el-aside {
      height: 100%;
      position: fixed;
      font-size: 0px;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1002;
    }
    .drawer-bg {
      background: #000;
      opacity: 0.3;
      width: 100%;
      top: 0;
      height: 100%;
      position: fixed;
      z-index: 1001;
    }
  }
  &.hideSidebar.mobile {
    .el-aside {
      width: 0 !important;
    }
  }
  &.hideSidebar {
    & .el-aside {
      width: 64px !important;
      transition: width 0.3s;
      & .el-menu-item.is-active::before {
        width: 0px;
      }
    }
    .el-menu--collapse .el-submenu > .el-submenu__title > span {
      height: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
      display: inline-block;
    }
    .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
      display: none;
    }
    .el-submenu [class^="el-icon-"] {
      position: absolute;
      font-size: $--font-size-medium;
      left: 20px;
      top: 20px;
    }
  }
}
</style>
