import user from '@/service/modules/user.js'
import { setGuide, getToken, setToken, removeToken, setTokenTime, getUserInfo, setUserInfo, removeUserInfo, setActiveMenu, setActiveApi } from '@/utils/localStorage.js'
import router from '../../modulars/Common/router' // 公共模块
// import router from '../../modulars/Overview/router'           // 数据总览
// import router from '../../modulars/DataPlatform/router' // 数据平台
// import router from '../../modulars/OperationPlatform/router' // 运维平台
// import router from '../../modulars/Middleware/router' // 业务中间件
// import router from '../../modulars/System/router' // 系统管理

const state = {
  token: getToken(),
  tokenTime: '',
  name: '',
  avatar: '',
  userInfo: getUserInfo(),
  isAskLogin: false,
  accessRoutes: [],
  cancelRequestArr: [] // 取消请求token数组
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_TOKEN_TIME: (state, tokenTime) => {
    state.tokenTime = tokenTime
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = JSON.stringify(info)
  },
  SET_IS_ASK_LOGIN: (state, val) => {
    state.isAskLogin = val
  },
  SET_ACCESSROUTES: (state, routes) => {
    state.accessRoutes = routes
  },
  pushRequest (state, payload) {
    state.cancelRequestArr.push(payload.cancelToken)
  },
  clearRequest ({ cancelRequestArr }) {
    // console.log(cancelRequestArr)
    cancelRequestArr.forEach(item => {
      if (item) {
        // console.log(item)
        item('路由跳转取消上个页面的请求队列')
      }
    })
    cancelRequestArr = []
  },
  emptyRequest (state, payload) {
    state.cancelRequestArr = []
  }
}

const actions = {
  // 初始化（加登录功能）
  init({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      user.init(userInfo).then(response => {
        const data = response
        if (parseInt(data.code) === 200) {
          commit('SET_TOKEN', { accessToken: data.data.accessToken, refreshToken: data.data.refreshToken })
          commit('SET_TOKEN_TIME', Date.parse(new Date()))
          commit('SET_NAME', data.data.currentUser.loginName)
          commit('SET_USER_INFO', data.data.currentUser)
          commit('SET_IS_ASK_LOGIN', false)
          setToken(JSON.stringify({ accessToken: data.data.accessToken, refreshToken: data.data.refreshToken }))
          setTokenTime(Date.parse(new Date()))
          setUserInfo(JSON.stringify(data.data.currentUser))
          setGuide(JSON.stringify(data.data.guideList))
          let apiList = []
          let menuList = []
          // 筛选接口权限
          data.data.permit.map(item => {
            if (item.action) {
              apiList.push(item.action)
            }
          })
          // 筛选菜单权限
          data.data.permit.map(item => {
            if (item.remark && item.remark !== '') {
              menuList.push(item.remark)
            }
          })
          // 保存接口权限
          setActiveApi(JSON.stringify(apiList))
          // 保存菜单权限
          setActiveMenu(JSON.stringify(menuList))
          resolve(data.data)
        } else {
          reject(data.message)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 登录
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      user.login(userInfo).then(response => {
        const data = response
        // console.log('登录成功：', data)
        if (parseInt(data.code) === 200) {
          if (data.data.success) {
            commit('SET_TOKEN', { accessToken: data.data.accessToken, refreshToken: data.data.refreshToken })
            commit('SET_TOKEN_TIME', Date.parse(new Date()))
            commit('SET_NAME', data.data.currentUser.loginName)
            commit('SET_USER_INFO', data.data.currentUser)
            commit('SET_IS_ASK_LOGIN', false)
            setToken(JSON.stringify({ accessToken: data.data.accessToken, refreshToken: data.data.refreshToken }))
            setTokenTime(Date.parse(new Date()))
            setUserInfo(JSON.stringify(data.data.currentUser))
            setGuide(JSON.stringify(data.data.guideList))
            let apiList = []
            let menuList = []
            // 筛选接口权限
            data.data.permit.map(item => {
              if (item.action) {
                apiList.push(item.action)
              }
            })
            // 筛选菜单权限
            data.data.permit.map(item => {
              if (item.remark && item.remark !== '') {
                menuList.push(item.remark)
              }
            })
            // 保存接口权限
            setActiveApi(JSON.stringify(apiList))
            // 保存菜单权限
            setActiveMenu(JSON.stringify(menuList))
          }
          resolve(data.data)
        } else {
          reject(data.message)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 刷新token
  resetToken({ commit }) {
    return new Promise(resolve => {
      // console.log('刷新Token')
      setTokenTime(Date.parse(new Date()))
      let refreshToken = JSON.parse(getToken('refreshToken')).refreshToken
      let accessToken = JSON.parse(getToken('accessToken')).accessToken
      // console.log('当前token：', accessToken)
      user.refresh({ 'accessToken': accessToken, 'refreshToken': refreshToken }).then(response => {
        const data = response
        if (data.code === 200) {
          // 更新token
          // console.log('更新token：', data.data)
          commit('SET_TOKEN', data.data.accessToken)
          commit('SET_TOKEN_TIME', Date.parse(new Date()))
          setToken(JSON.stringify({ accessToken: data.data.accessToken, refreshToken: data.data.refreshToken }))
          resolve(data)
        }
      })
    })
  },
  // 退出登录
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      user.logout().then(() => {
        // 退出登录时清除刷新token定时器
        commit('SET_TOKEN', '')
        commit('SET_USER_INFO', '')
        commit('SET_ACCESSROUTES', [])
        commit('SET_IS_ASK_LOGIN', false)
        removeToken()
        // console.log('清除token:', getToken())
        removeUserInfo()
        setActiveMenu()
        resolve()
        router.push({ path: '/login' })
      }).catch(error => {
        reject(error)
      })
    })
  },
  setAccessRoutes({ commit, state }, args) {
    return new Promise(resolve => {
      commit('SET_ACCESSROUTES', args)
      resolve()
    })
  }
}

export default {
  namescaped: false,
  state,
  mutations,
  actions
}
